@charset "UTF-8";
/****************!
 * Theme Name: RAC Club Theme
 * URL: https://www.rac.com.au
 * Tokiomarine LTD
 **************************************/
@mixin steps-rac {
  .footer-bottom {
    padding-bottom: 120px !important;
  }

  /********* Counter CSS for Step Form **********/
  section.bg-info.shadow.sticky-top {
    background: #182d3c !important;
  }
  section.bg-info.shadow.sticky-top form.ng-untouched small {
    color: #ffd100 !important;
  }
  div#bottom-bar h2 {
    font-size: 24px;
  }
  .step-container {
    position: relative;
    text-align: center;
    transform: translateY(-28%);
  }

  .step-circle {
    width: 40px;
    height: 40px;
    font-size: 24px;
    border-radius: 50%;
    background-color: #fff;
    line-height: 30px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    cursor: pointer;
  }
  .step-circle.bg-info {
    background-color: #425563 !important;
  }
  .step-circle-container .progress-bar.bg-info {
    background-color: #425563 !important;
  }
  .step-container .step-text {
    margin-left: -10px;
  }
  .step-line {
    position: absolute;
    top: 16px;
    left: 50px;
    width: calc(100% - 100px);
    height: 2px;
    background-color: #182d3c;
    z-index: -1;
  }
  .step-container div:last-child {
    flex-direction: column;
    display: flex;
    align-items: end;
  }
  .col.depart-date {
    padding-right: 20px;
  }

  .col.travellernumber {
    max-width: 100px;
  }

  /* bnt size fix*/
  .col.updatetrip .btn.btn-info {
    background: #0067f6;
    color: #fff;
  }
  label.btn.szbtn {
    width: 105px;
  }
  .btn.szbtn {
    width: 105px;
  }

  #sticky-quote-bar {
    z-index: 500;
  }
  /** step one inside tab fix **/
  .row.benifitssections {
    margin-left: 0px;
    margin-right: 0px;
  }

  /*steps footer fix*/
  .theme-steps .footer {
    padding-bottom: 100px;
  }
  /*table border step1*/
  tr:last-child td:nth-child(1) {
    border-radius: 0px 00px 0px 20px !important;
  }
  tr:last-child td:last-of-type {
    border-radius: 0px 0px 20px 20px !important;
  }

  /*end table border step1*/

  .benefits-tbl-border {
    border: 1px solid #e3e0d1;
    border-top: 0;
    border-radius: 0 0 20px 20px;
  }
  /**** End Counter Step Form CSS *****/

  /************ Step-2 Summery  Blue Header Part CSS Start*****************/
  .medical-canvas {
    border-radius: 10px 0 0 10px;
  }
  input.form-control::placeholder {
    color: #b1b8bd;
  }
  .btn-back {
    font-size: 32px;
    margin-top: -20px;
    transition: ease 0.2s;
  }
  .btn-back:hover {
    transform: scale(1.5);
    color: #0dcaf0 !important;
  }

  #traveller_summery_edit,
  #contact_edit {
    border-radius: 10px 0 0 10px;
  }

  .number-add-plus {
    font-size: 25px;
    padding: 5px 12px;
    line-height: 25px;
    cursor: pointer;
    border: 1px solid #ddd;
    border-left: 0px;
    border-radius: 0 5px 5px 0;
    color: #b1b8bd;
  }
  .number-add-minus {
    font-size: 25px;
    padding: 2px 12px;
    line-height: 25px;
    cursor: pointer;
    border: 1px solid #ddd;
    border-right: 0px;
    border-radius: 5px 0 0 5px;
    color: #b1b8bd;
  }

  /* General input placeholder styling */
  ::placeholder {
    color: #ccc; /* The color you want for the placeholders */
    opacity: 1; /* Ensures the color is applied */
  }

  /* Webkit browsers (Chrome, Safari, Edge) */
  input::-webkit-input-placeholder {
    color: #ccc; /* The color you want for the placeholders */
  }

  /* Mozilla Firefox */
  input:-moz-placeholder {
    color: #ccc; /* The color you want for the placeholders */
  }

  /* Internet Explorer */
  input:-ms-input-placeholder {
    color: #ccc; /* The color you want for the placeholders */
  }

  /* Specific for p-calendar input if needed */
  .p-inputtext::placeholder {
    color: #ccc; /* The color you want for the placeholders */
  }
  /* *********End Step-2 Summery CSS************* */

  .font-20 {
    font-size: 20px;
  }
  .font-bold {
    font-weight: 700;
  }
  .popover {
    max-width: 500px !important;
    min-width: 280px;
  }
  .input-text-search {
    display: inline-block;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 5px 0 0 5px;
    border-right: unset;
    color: #ccc;
  }

  .datepicker table {
    min-width: 230px;
  }
  .disabled-date {
    pointer-events: none;
    opacity: 0.2;
    background: #eee;
  }

  section.header.shadow {
    background: #0067f6;
  }
  /*************Tabs CSS Start************/
  div#nav-tab {
    display: flex;
    justify-content: space-around;
  }

  div#nav-tab .nav-link {
    width: 333.339%;
    position: relative;
  }
  button.topc.nav-link.active,
  button.essencover.nav-link.active,
  button.bascover.nav-link.active {
    background: #0067f6;
    color: #fff;
  }

  /*****************Table CSS Start************/
  .table-row {
    cursor: pointer;
    position: relative;
  }
  .table-row::after {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\f107';
    position: absolute;
    right: 15px;
    top: 15px;
    color: #182d3c;
    border: 1px solid;
    border-radius: 50%;
    font-size: 12px;
    line-height: 20px;
    width: 20px;
    height: 20px;
    text-align: center;
  }
  .table-row:not(.collapsed)::after {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\f106';
    position: absolute;
    right: 15px;
    top: 15px;
    color: #182d3c;
  }
  .table-row td:first-child {
    width: 35%;
  }
  .table-row td:last-child {
    /* Edit After Marge */
    padding-right: 25px !important;
  }
  table.compare.text-left {
    text-align: left;
    border-color: #e3e0d1;
  }
  .table > :not(caption) > * > * {
    padding: 0.7rem 1rem !important;
  }
  .table > :not(:first-child) {
    border-top: 1px solid currentColor !important;
  }
  th.bg-info-light {
    background: #f3f2ec !important;
    border: 1px solid #e3e0d1 !important;
  }

  th.bg-info-light.firstth {
    border-radius: 6px 0px 0px 0px;
  }
  th.bg-info-light.lastth {
    border-radius: 0px 6px 0px 0px;
  }
  .table-bordered > :not(caption) > * > * {
    border-width: 1px !important;
  }
  tr.table-row.collapsed td.lasttdleft {
    border-radius: 0px 0px 0px 6px !important;
  }
  tr.table-row.collapsed td.lasttdright {
    border-radius: 0px 0px 6px 0px;
  }
  td.thelasttd {
    border-radius: 0px 0px 6px 20px;
  }
  table {
    border-spacing: 0px !important;
    border-collapse: revert-layer !important;
  }
  table.compare tr td {
    border-left: 0px solid #e3e0d1 !important;
    border-right: 1px solid #e3e0d1 !important;
    border-bottom: 1px solid #e3e0d1 !important;
  }

  table.compare tr td:nth-child(1) {
    border-left: 1px solid #e3e0d1 !important;
  }
  /*********End table css************/

  .p-tabview-nav-content {
    overflow: unset;
  }
  .mostpopular {
    width: 33.3333%;
    line-height: 40px;
    text-align: center;
    color: #182d3c;
    background: #ffd100;
    z-index: 9;
    height: 71px;
    margin-bottom: -25px;
    border-radius: 6px 6px 0px 0px;
    font-weight: 700;
  }

  button#topcover {
    border-radius: 6px 0px 0px 0px;
    border: 1px solid #e3e0d1;
  }

  button#essentialcover {
    border-radius: 0px;
    border: 1px solid #e3e0d1;
  }

  button#basiccover {
    border-radius: 0px 6px 0px 0px;
    border: 1px solid #e3e0d1;
  }

  .nav.nav-tabs .nav-link.active .text-dark {
    color: #fff !important;
  }

  .nav.nav-tabs .nav-link {
    background: #f3f2ec;
    transition: color 0s ease-in-out, background-color 0s ease-in-out,
      border-color 0s ease-in-out !important;
  }

  .nav-link h4 {
    font-weight: 700 !important;
  }
  p.covidalert {
    background: #f8db00;
    color: #0067f6;
  }
  .graybg {
    background: #f8f8f8;
  }

  .withborder {
    border: 1px solid #e3e0d1 !important;
    border-radius: 8px;
  }
  .insider img {
    height: 80px;
  }
  .insider img.emergence {
    height: 80px;
  }
  .eqwidth {
    width: 32.3%;
  }
  .pregraybg {
    background: #f8f8f8;
  }

  .conditionsapply .graybg {
    background: #f8f8f8;
  }
  .conditionsapply p.text-left {
    text-align: left;
    font-size: 14px;
    line-height: 26px;
    color: #182D3C  !important;
  }

  .conditionsapply {
    text-align: left;
  }

  .container-fluid.fixed-bottom {
    background: #fff;
    box-shadow: 0px 4px 5px 2px #000;
  }
  .offcanvas-bottom {
    width: 78% !important;
    height: 88vh !important;
    border-radius: 25px 25px 0 0;
    max-width: 1020px;
    max-height: 630px;
    margin: 0 auto !important;
  }
  .offcanvasbuttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .btn-block {
    display: block;
  }
  .offcanvasbuttons .btn {
    width: 100% !important;
  }
  span.text-gray,
  p.offc.label {
    color: #182D3C;
  }
  .btn-outline-info {
    color: #001657;
    border-color: #001657;
    margin-right: 5px;
  }

  a {
    cursor: pointer !important;
  }
  .modal-header {
    border-bottom: 0px !important;
  }
  .modal-footer {
    border-top: 0px !important;
  }
  div#bottom-bar h2 {
    font-weight: 700;
  }
  .pricebrkdwn .btn-outline-info {
    font-weight: 500 !important;
  }
  span.fw-500.text.pre-existing {
    font-weight: 500;
    color: #001657;
  }
  /**************** Select2 Extra CSS  *******************/
  .select2-container--default
    .select2-selection--multiple
    .select2-selection__choice {
    background-color: #e5ecff;
  }
  .select2-container--default
    .select2-selection--multiple
    .select2-selection__choice__remove {
    margin-left: 5px;
    float: right;
    border: 1px solid #263238;
    border-radius: 35px;
    height: 20px;
    width: 20px;
    line-height: 20px;
    text-align: center;
    padding: 0;
    margin-top: 2px;
    color: #263238;
  }

  #travelDistination .select2-selection__rendered li {
    display: none;
  }
  #travelDistination .select2-selection__rendered li:last-child {
    display: block;
  }

  .tags-container {
    list-style-type: none;
  }
  .tags-container li {
    display: inline-block;
    padding: 5px 10px;
    background: #e5ecff;
    border-radius: 6px;
    margin: 5px;
  }
  .tags-container li.tag-selected a {
    float: right;
    border: 1px solid;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    line-height: 16px;
    text-align: center;
    text-decoration: none;
    padding: 0;
    margin-left: 5px;
    color: #000;
    margin-top: 2px;
  }
  #travelDistination
    .select2-container
    .select2-search--inline
    .select2-search__field {
    height: 30px;
    padding: 5px;
    border-radius: 0 5px 5px 0;
  }
  #travelDistination
    .select2-container--default.select2-container--focus
    .select2-selection--multiple {
    border-color: #ddd;
    border-radius: 0 5px 5px 0;
    border-left: 0;
  }
  #travelDistination .select2-container--default .select2-selection--multiple {
    border-left: unset;
    border-radius: 0 5px 5px 0;
    border-color: #ddd;
  }
  #travelDistination .select2-dropdown {
    z-index: 999999;
  }
  .input-date:focus {
    border: unset;
    outline: unset;
  }

  span.select2-container.select2-container--default.select2-container--open {
    z-index: 99999999999999;
  }

  /* *******End Select 2 CSS******** */

  div#nav-tabContent .text-primary {
    color: #182d3c !important;
  }

  .text-custom {
    color: #182d3c;
  }

  .text-custom:hover {
    color: #182d3c;
  }
  .active .text-custom {
    color: #fff;
  }

  .depart-date input {
    cursor: pointer;
  }
  .depart-date-span {
    margin-left: -05px;
    color: #ffd100;
  }

  .datepicker table tr td.active.active,
  .datepicker table tr td.active.disabled,
  .datepicker table tr td.active.disabled.active,
  .datepicker table tr td.active.disabled.disabled,
  .datepicker table tr td.active.disabled:active,
  .datepicker table tr td.active.disabled:hover,
  .datepicker table tr td.active.disabled:hover.active,
  .datepicker table tr td.active.disabled:hover.disabled,
  .datepicker table tr td.active.disabled:hover:active,
  .datepicker table tr td.active.disabled:hover:hover,
  .datepicker table tr td.active.disabled:hover[disabled],
  .datepicker table tr td.active.disabled[disabled],
  .datepicker table tr td.active:active,
  .datepicker table tr td.active:hover,
  .datepicker table tr td.active:hover.active,
  .datepicker table tr td.active:hover.disabled,
  .datepicker table tr td.active:hover:active,
  .datepicker table tr td.active:hover:hover,
  .datepicker table tr td.active:hover[disabled],
  .datepicker table tr td.active[disabled] {
    background-color: #0067f6 !important;
    background: #0067f6 !important;
  }
  .datepicker table tr td.today,
  .datepicker table tr td.today.disabled,
  .datepicker table tr td.today.disabled:hover,
  .datepicker table tr td.today:hover {
    background-color: #85b8ff !important;
    background-image: linear-gradient(to bottom, #85b8ff, #85b8ff) !important;
  }

  .daterangepicker td.active,
  .daterangepicker td.active:hover {
    background-color: #ffc908 !important;
  }

  .drp-buttons .btn-primary {
    color: #182d3c !important;
    background-color: #ffc908 !important;
    border-color: #ffc908 !important;
  }

  .lightcbg {
    background: #edf7f9;
    padding: 10px;
  }

  .dob.steptwo input {
    padding: 0.375rem 0.75rem !important;
  }

  /*************** Email & Download Quote Icons CSS ********************** */
  .save-quote {
    position: fixed;
    right: 0;
    margin-right: 0px !important;
    top: 300px;
    z-index: 999;
    background: #fff;
  }
  .save-quote span {
    display: none;
  }
  .save-quote:hover > span {
    display: inline-block;
  }
  .save-quote:hover img {
    margin-left: 4px;
  }

  a.btn.btn-outline-info.save-quote {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    background: #fff;
  }
  a.btn.btn-outline-info.save-quote:hover {
    color: #fff;
    background-color: #001657;
    border-color: #001657;
  }

  .download-quote {
    position: fixed;
    right: 0;
    margin-right: 0px !important;
    top: 340px;
    background: #fff;
    z-index: 999;
  }
  .download-quote span {
    display: none;
  }
  .download-quote:hover > span {
    display: inline-block;
  }
  .save-quote:hover > img,
  .download-quote:hover > img {
    filter: contrast(0%) brightness(200%);
  }

  a.btn.btn-outline-info.download-quote {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  a.btn.btn-outline-info.download-quote:hover,
  a.btn.btn-outline-info.save-quote:hover {
    color: #fff;
    background-color: #182d3c;
    border-color: #182d3c;
  }
  /* **********End Quote CSS************* */



  /**************************
    Code For mobile Version. 
******************************/
  .m-toggle-icon {
    display: inline-block;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: #fff;
    border: 1px solid;
    border-radius: 50%;
    cursor: pointer;
  }
  .select2-container--default .select2-selection--multiple {
    min-height: 45px !important;
    /* border-left: unset !important; */
    /* border-radius: 0 5px 5px 0 !important; */
    border-color: #ced4da !important;
  }
  .tags-container > ul {
    padding-left: 0px;
  }

  /********* Step-2 Summery Header Mobile View CSS Code *********/
  section#sticky-quote-bar small {
    color: #ffd100;
  }
  section#sticky-quote-bar button span {
    color: #ffd100;
    margin-left: 10px;
  }
  #mobile-sticky-toggle-bar {
    display: none;
  }
  #mobile-sticky-toggle-bar {
    z-index: 9998;
    overflow-y: scroll !important;
    padding-bottom: 20px;
  }
  div#updatequote {
    z-index: 9999999;
  }
  .p-overlaypanel {
    z-index: 99999991 !important;
  }
  .toggle-item-content .btn-outline-info {
    color: #9e9e9e;
    border-color: #9e9e9e;
    margin-right: 5px;
  }
  #mobile-toggle-section {
    display: none;
    background: #182d3c;
    height: 100% !important;
    overflow-y: scroll !important;
  }
  div#mobile-toggle-section .text-gray {
    color: #fff;
  }

  div#mobile-toggle-section .text-info {
    color: #fff !important;
  }
  .quote-mobile-view > ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  .quote-mobile-view > ul > li {
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 10px #ddd;
    padding: 20px;
    border-radius: 10px;
  }

  .toggle-item-content {
    display: none;
  }

  .active .header-title {
    font-size: 20px;
    font-weight: 700;
    color: #000;
    margin-bottom: 20px;
  }

  .daterangepicker .drp-calendar.right {
    display: none !important;
  }
  #customDateRange .daterangepicker {
    top: auto !important;
    left: auto !important;
    text-align: center;
    position: relative;
    width: 280px;
  }
  #customDateRange .daterangepicker .ranges,
  .daterangepicker .drp-calendar {
    float: unset !important;
  }

  .daterangepicker .calendar-table .next span,
  .daterangepicker .calendar-table .prev span {
    border: solid #182d3c !important;
    border-width: 0 2px 2px 0 !important;
  }

  .daterangepicker td.in-range {
    background-color: #182d3c !important;
    color: #fff !important;
  }
  .daterangepicker select.monthselect,
  .daterangepicker select.yearselect {
    background: #0067f6;
    border-radius: 4px;
    border: unset;
    color: #fff;
    text-align: center;
  }
  .daterangepicker select.monthselect:focus,
  .daterangepicker select.yearselect:focus {
    outline: unset !important;
    border: unset !important;
  }
  .btn-toggle-selector {
    cursor: pointer;
  }

  #mdateCruise .datepicker table,
  #mskiSports .datepicker table {
    min-width: 300px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 13px;
    line-height: 25px;
  }
  .datepicker .datepicker-switch {
    background: #0067f6;
    color: #fff;
    border: 2px solid #fff;
    border-right: 8px solid #fff;
    border-left: 8px solid #fff;
  }
  .datepicker .datepicker-switch:hover {
    background: #0067f6 !important;
  }
  .datepicker th.next,
  .datepicker th.prev {
    margin: 5px;
    border: 1px solid #0067f6;
    border-radius: 50%;
    outline: 2px solid #fff;
    color: #0067f6;
  }

  .daterangepicker .calendar-table th.next,
  .daterangepicker .calendar-table th.prev {
    position: relative;
  }

  .daterangepicker .calendar-table th.next::after {
    content: '';
    border: 1px solid #0067f6;
    border-radius: 50%;
    width: 25px !important;
    height: 25px !important;
    display: block;
    position: absolute;
    top: 0px;
    left: 6px;
  }

  .daterangepicker .calendar-table th.prev::after {
    content: '';
    border: 1px solid #0067f6;
    border-radius: 50%;
    width: 25px !important;
    height: 25px !important;
    display: block;
    position: absolute;
    top: 0px;
    left: 3px;
  }
  .daterangepicker .calendar-table th.next:hover,
  .daterangepicker .calendar-table th.prev:hover {
    background: transparent !important;
  }

  // .compare-accordion-header {
  //   display: none;
  // }

  .text-lg-right {
    text-align: right;
  }

  .benifitssection {
    border-left: 1px solid #e3e0d1;
    border-right: 1px solid #e3e0d1;
    padding-bottom: 40px;
    margin-top: -1px;
  }
  .compare-benefits {
    background: #fff;
    margin-top: -30px;
    border: 1px solid #e3e0d1;
    border-top: 0;
    border-radius: 0 0 20px 20px;
  }

  /*********************** Step 02 CSS  ********************************/
  .borderpre {
    border: 1px solid #e3e0d1;
    border-radius: 20px;
  }

  a.centerreadmore {
    display: none;
  }

  a.centerreadmore.collapsed {
    text-align: center;
    display: block;
    text-decoration: none;
    color: #000000;
  }
  ol.threecol {
    column-count: 3;
    column-gap: 50px;
    padding: 0;
    @media screen and (max-width: 1024px) {
      column-count: 1;
    }
    @media screen and (min-width: 1024px) and (max-width: 1200px) {
      column-count: 2;
    }
  }
  h2.travelperson {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  h2 small {
    font-size: 14px;
    padding-left: 10px;
  }

  #discon1 label.text-secondary {
    color: #182D3C !important;
  }

  /*************** OFf Canvas CSS  *****************/
  .offcmedaccess {
    width: 30px;
    top: 10px;
    right: 0% !important;
    display: block;
    position: absolute;
  }

  .font-22 {
    font-size: 22px;
  }
  #assessment_04 .accordion-item:first-of-type {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
  #assessment_04 .accordion-item:last-of-type {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  #assessment_04 .accordion-header:not(.collapsed) {
    border-bottom: 1px solid #e3e0d1;
  }

  .btn-light-info {
    border-radius: 35px;
  }

  .btn-check:checked + .btn-light-info,
  .btn-check:active + .btn-light-info,
  .btn-light-info:active,
  .btn-light-info.active,
  .btn-light-info.dropdown-toggle.show {
    background-color: #ffefb8 !important;
    border-color: #ffc908 !important;
    color: #182d3c !important;
  }
  a.btn.btn-outline-info.save-quote {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  a.btn.btn-primary {
    display: none;
  }
  a.btn.btn-primary.collapsed {
    display: block;
  }
  .disabledcontent {
    pointer-events: none;
    opacity: 0.5;
  }
  .summary-title .text-info {
    color: #182d3c !important;
  }

  /* Plus Sign  for Accordion */
  .accordion-header {
    position: relative;
    cursor: pointer;
  }
  .accordion-header::after {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\2b';
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 22px;
    color: #182d3c;
  }
  .accordion-header:not(.collapsed)::after {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\f068';
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 22px;
    color: #182d3c;
  }

  /********* Step O3 CSS   **************** */

  .fw-semibold {
    font-weight: 500;
  }

  .col.myflexend {
    display: flex;
    justify-content: space-between;
  }
  td.text-secondary.mobtd {
    max-width: 110px;
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .summary-result {
    border-radius: 20px;
  }
  .summary-result .text-secondary {
    color: #182D3C !important;
  }
  .tsummeryedit img.tooltipc {
    width: 24px;
    height: 24px;
    margin-right: 10px;   
   }
   .tsummeryedit .smalltext{
    margin-right: 10px;
   }
   
  .adjustablewidth {
    min-width: 120px;
    text-transform: capitalize;
}

  /************ Step 04 CSS  *************  */

  #paypal {
    margin-top: -25px;
  }
  .text-red {
    color: red;
  }
  #otherpay {
    display: none;
  }
  section#loading {
    position: absolute;
    z-index: 999999;
    background: #fff;
    width: 100%;
    height: 80%;
  }
  .col-10.myflexendthankyou {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
  .text-gray {
    color: #656565;
  }

  /********  Promo **********/
  .promo {
    background-color: #ffefb8;
    border: 1px dashed #ffc908;
    border-radius: 5px;
    padding: 3px 10px;
  }
  .promo .close-promo {
    color: #182d3c;
    height: 20px;
    width: 20px;
    border-radius: 25px;
    border: 1px solid;
    display: inline-block;
    text-align: center;
    line-height: 15px;
    cursor: pointer;
    font-weight: 400;
  }

  .bg-light-info {
    background-color: #ffefb8;
  }
  .bg-light-info.thankyou {
    color: #182d3c;
  }
  .info-icon {
    display: inline-block;
    width: 25px;
    height: 25px;
    line-height: 20px;
    color: #182d3c;
    border: 1px solid #182d3c;
    border-radius: 35px;
    text-align: center;
    font-size: 12px;
  }
  .gateway-icon {
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .gateway-icon img {
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  /*****************************
    Helper CSS
******************************/
  .font-12 {
    font-size: 12px !important;
  }

  /********************  for the step one primeNG css class ***********************************************************/
  .p-tabview-nav-content {
    overflow: unset;
  }
  .p-tabview-nav {
    padding-left: 0 !important;
    margin-bottom: 1px;
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 0;
  }
  .p-tabview .p-tabview-nav li a {
    text-decoration: none;
    color: #000;
  }
  .p-tabview .p-tabview-nav li {
    margin-right: 0;
    width: 33.33333%;
  }
  .row.align-items-end.text-start.text-custom {
    z-index: 100;
  }
  .p-highlight .p-tabview-nav-link {
    .topcover {
      background: #182d3c;
      color: #fff;
      .text-dark {
        color: #fff !important;
      }
    }
    .essentialcover {
      background: #33424d;
      color: #fff;
      .text-dark {
        color: #fff !important;
      }
    }
    .basiccover {
      background: #425563;
      color: #fff;
      .text-dark {
        color: #fff !important;
      }
    }

    .topcover::after {
      content: '';
      width: 40px;
      height: 40px;
      background: #182d3c !important;
      position: absolute;
      bottom: -10px;
      left: 42%;
      rotate: 45deg;
      z-index: -1;
    }
  }
  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: #ffffff;
    border-color: #182d3c;
    overflow: unset;
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    font-weight: normal;
  }

  .p-tabview .p-tabview-nav li:nth-child(1) a {
    border-radius: 6px 0px 0px 0px;
    border: 1px solid #e3e0d1;
  }
  .p-tabview .p-tabview-nav li:nth-child(2) a {
    border-radius: 0px;
    border: 1px solid #e3e0d1;
  }
  .p-tabview .p-tabview-nav li:nth-child(3) a {
    border-radius: 0px 6px 0px 0px;
    border: 1px solid #e3e0d1;
  }
  .p-tabview-panels {
    padding: 0px;
  }
  .choose-option-card .p-card {
    margin: 1rem;
    border-radius: 20px;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    box-shadow: none;
    margin-top: 30px;
    .p-card-body {
      padding: 1rem;
      .p-card-title {
        padding: 0;
        font-size: 20px;
      }
      .p-card-content {
        padding: 0;
      }
    }
  }

  //table
  tbody.p-element.p-datatable-tbody tr td {
    border-right: 1px solid #e3e0d1 !important;
    border-bottom: 1px solid #e3e0d1 !important;
  }

  tbody.p-element.p-datatable-tbody tr td:nth-child(1) {
    border-left: 1px solid #e3e0d1 !important;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    padding: 0.7rem 1rem !important;
    align-content: start;
  }

  .p-dialog-mask {
    z-index: 9999 !important;
  }

  /********************  Media/Responsive CSS Code ***********************************************************/

  @media screen and (max-width: 1020px) and (min-width: 768px) {
    .moboptbtn {
      padding: 6px 10px !important;
    }
    div#bottom-bar h2 {
      font-size: 18px;
    }
  }
  @media screen and (min-width: 1024px) and (max-width: 1250px) {
    div#bottom-bar h2 {
      font-size: 21px !important;
    }
  }
  @media screen and (max-width: 820px) {
    #mobile-sticky-toggle-bar {
      display: block;
    }
    #sticky-quote-bar {
      display: none;
    }
    .depart-date-span {
      margin-left: unset;
    }
    .daterangepicker .drp-calendar.right {
      display: none !important;
    }
    .compare {
      display: none !important;
    }
    .table-row td:first-child {
      width: 100%;
    }
    .table > :not(caption) > * > * {
      padding: 0px !important;
    }
    #compare-table {
      text-align: left;
    }
    .compare-accordion-header {
      display: block;
      font-weight: 600;
      background: #f3f2ec;
    }
    #compare-table .accordion-item {
      border-color: #f3f2ec !important;
      border-radius: 0 !important;
    }
    #compare-table .accordion-button,
    #compare-table .accordion-body {
      font-size: 14px !important;
    }
    #compare-table .accordion-button::after {
      width: 1.5rem !important;
      height: 1.5rem !important;
      border-color: #182d3c !important;
      border: 1px solid #182d3c;
      line-height: 1.5rem;
      background-position: center;
      border-radius: 50%;
      background-size: 50% !important;
    }
  }

  @media screen and (max-width: 640px) {
    section.header.sticky-top.solidheader{
      top:-2px!important;
    }
    /*tab*/
    .ifdiscountedprice {
      height: 45px;
    }
    .nav.nav-tabs .nav-link {
      padding: 0.5rem 0.05rem !important;
    }
    .nav-link h4 {
      font-weight: 500 !important;
    }
    .insider img {
      height: 45px;
    }
    .insider img.emergence {
      height: 45px;
    }
    .insider h4 {
      font-size: 14px;
      font-weight: 700;
    }
    h4.text-left.mb-4.covertitle {
      font-size: 14px;
      font-weight: 500;
    }
    .covprice {
      font-size: 20px;
      font-weight: 700;
    }
    .mostpopular.pt-1 {
      font-size: 12px;
      line-height: 16px;
      height: 65px;
    }
    .insider span.text-primary.fw-semibold {
      font-size: 12px !important;
    }
    .row.align-items-end.text-start.text-custom {
      z-index: 1;
      position: relative;
    }
    .nav.nav-tabs .nav-link.active::after {
      z-index: 0;
    }
    .text-custom p {
      font-size: 12px;
    }
    .stickyftbutton {
      width: 100%;
    }

    .container-fluid.fixed-bottom h2 {
      font-size: 18px !important;
      font-weight: 700;
      margin-bottom: 2px;
    }
    .offcanvas-bottom {
      width: 99% !important;
      left: 0% !important;
      height: 100vh !important;
    }
    .offcanvasbottom {
      padding-bottom: 50px;
    }
    .withborder {
      border: 0px solid #f8f8f8 !important;
      border-radius: 8px;
    }
    .offcanvasbuttons .btn.stickyftbutton {
      width: 94% !important;
      position: fixed;
      bottom: 0px;
      left: 3%;
    }
    .offcanvasbottom.dflex.mobstyle {
      padding-top: 20px;
      display: flex;
      width: 100%;
      position: relative;
      margin-bottom: 30px;
    }
    .mobstyle p.offc.label,
    .mobstyle p.offclabel {
      font-size: 16px !important;
      line-height: 30px;
      padding-left: 10px;
    }
    .mobstyle h3 {
      font-size: 26px !important;
      padding-left: 10px;
    }
    .pricebrkdwn h3 {
      font-size: 16px;
    }
    .pricebrkdwn {
      padding-top: 5px;
    }
    .modal-header {
      padding: 0.5rem;
    }
    .modal-body {
      padding: 0.5rem;
    }
    .modal-body p {
      font-size: 14px !important;
    }

    .header-top {
      display: none;
    }
    .ifdiscountedprice .tab-title {
      font-size: 14px;
    }
    .ifdiscountedprice .tab-title-2 {
      font-size: 14px;
    }
    .only-mobile-none {
      display: none !important;
    }
    .text-m-center {
      text-align: center !important;
    }

    /* bnt size fix*/
    label.btn.szbtn {
      width: 95px;
    }
    .btn.szbtn {
      width: 95px;
    }

    /* compare table responsive CSS */
    .table-row td:first-child {
      width: 100%;
    }
    .table > :not(caption) > * > * {
      padding: 0px !important;
    }
    .compare {
      display: none !important;
    }
    #compare-table {
      text-align: left;
    }
    .compare-accordion-header {
      display: block;
      font-weight: 600;
      background: #f3f2ec;
    }
    #compare-table .accordion-item {
      border-color: #f3f2ec !important;
      border-radius: 0 !important;
    }
    #compare-table .accordion-button,
    #compare-table .accordion-body {
      font-size: 14px !important;
    }
    #compare-table .accordion-button::after {
      width: 1.5rem !important;
      height: 1.5rem !important;
      border-color: #0067f6 !important;
      border: 1px solid #0067f6;
      color: #0067f6;
      line-height: 1.5rem;
      background-position: center;
      border-radius: 50%;
      background-size: 50% !important;
    }
    .addoption,
    .stickyproceed {
      margin: 2%;
      width: 46%;
      float: left;
    }
    .moboptbtn {
      width: 32%;
    }

    /* Step 02 Responsive CSS */
    .mobhalf {
      width: 48%;
    }
    .mobfull {
      width: 100%;
    }
    h2.travelperson {
      font-size: 20px;
    }
    .flexgrid {
      flex-wrap: wrap;
    }
    .btn50 {
      width: 50%;
    }
    .btn45 {
      width: 45%;
    }
    .medmob {
      width: 100% !important;
    }
    .medmob .btn {
      width: 48%;
      margin-bottom: 5px;
    }
    .preselection .btn {
      width: auto;
      padding: 10px 20px !important;
    }
    .medmob .text-end {
      text-align: center !important;
    }
    .select2-container {
      width: 100% !important;
    }
    .medmobauto .btn {
      width: auto;
      margin-bottom: 5px;
    }
    .text-m-center {
      text-align: center !important;
    }

    /*Step 03 Responsive CSS*/
    td.text-secondary.mobtd {
      width: 35%;
    }

    .mobadjust {
      width: 100%;
    }
    .mobadjust .btn.mobhalf {
      width: 48%;
      margin-top: 10px;
    }
    .offcanvas.offcanvas-end {
      width: 100% !important;
      overflow: scroll !important;
    }
    .offcanvastop.d-flex {
      flex-direction: column;
    }

    .eqwidth {
      width: 31.3%;
    }
  }

  @media screen and (max-width: 767px) and (min-width: 641px) {
    .addoption,
    .stickyproceed {
      margin-right: 1%;
      width: 48%;
      float: left;
    }
    .stickyproceed {
      margin-left: 1%;
      width: 48%;
      float: left;
    }
    .text-m-center {
      text-align: center !important;
    }
    .offcanvastop.d-flex {
      flex-direction: row;
    }
  }
  @media screen and (min-width: 767px) {
    .offcanvastop.d-flex {
      display: flex !important;
      flex-direction: column;
    }
  }

  /* Desktop view */
  @media (min-width: 768px) {
    .last-of-type {
      border-radius: 0px 20px 0px 0px;
    }
  }

  /* Mobile view */
  @media (max-width: 767px) {
    .p-datatable .p-datatable-thead > tr > th:last-of-type {
      border-radius: 0px;
    }
    .quotes-steps-one .footer-bottom,
    .quotes-steps-two .footer-bottom {
      padding-bottom: 185px !important;
    }
    .promo {
      font-size: 12px;
    }
  }
  @media (max-width: 520px) {
    .quotes-steps-one .footer-bottom,
    .quotes-steps-two .footer-bottom {
      padding-bottom: 180px !important;
    }
    .topcover::after {
      left: 34% !important;
    }
  }
  @media screen and (max-width: 440px) {
    .promo {
      display: block; 
    }
  }

  @media (min-width: 640px) and (max-width: 767px) {
    .ifdiscountedprice .tab-title {
      font-size: 16px;
      height: 30px;
    }
  }

  .error-page {
    margin: 30px;
    border-radius: 5px;
    background: #f7f7f7;
    box-shadow: 0 1px 4px 1px #b4b6a9, 2px 2px 1px 0 #fff inset;
    padding: 20px;
  }

  .amt-tabview {
    .p-tabview-nav li {
      width: 100%;
    }
  }
}
