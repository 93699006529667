//GLOBAL
@import 'assets/css/indigo-pink.scss'; //this is for verisk
.cdk-overlay-container {
  @include verisk-theme;
}
@import 'assets/css/ractheme/loading-mask.scss';
//GLOBAL OVERRIDING STYLE (VERISK)
.ReactModalPortal {
  position: fixed !important;
  z-index: 10000 !important;
  .modal-dialog {
    top: 20%;
  }

  button.verisk-btn {
    width: 200px;
    position: relative;
    margin: 0 auto;
    right: -10px;
  }
}
// RAC Theme
@import 'assets/css/ractheme/steps.scss';
@import 'assets/css/ractheme/style.scss';
@import 'assets/css/ractheme/style-home.scss';
@import 'assets/css/ractheme/ng-select-ant.design.theme.scss';
.club-theme {
  margin: 0 0px 0 -2px;
  overflow: auto !important;
  @include loading-mask;
  @include travel-destination-clubs;

  .theme-search {
    @include style-home-rac;
  }
  .theme-steps {
    @include steps-rac;
    @include style-rac;
  }
  .theme-static {
    @include style-home-rac;
    @include style-rac;
  }
}
